body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  background-color: #fff;
  color: #000;
  transition:
    background-color 0.3s,
    color 0.3s;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza verticalmente todo o conteúdo */
  align-items: center; /* Centraliza horizontalmente todo o conteúdo */
}

.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
}

.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-bottom: 50px; /* Espaço para o footer */
}

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 100%;
  text-align: center;
}

.footer {
  text-align: center;
  padding: 1% 0;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.landing-title {
  font-size: 2.5rem; /* Tamanho padrão para desktop */
}

.landing-description {
  font-size: 1.25rem; /* Tamanho padrão para desktop */
}

@media (max-width: 600px) {
  .landing-container {
    padding: 10px;
  }

  .landing-title {
    font-size: 1.75rem; /* Tamanho ajustado para dispositivos móveis */
  }

  .landing-description {
    font-size: 1rem; /* Tamanho ajustado para dispositivos móveis */
  }

  .header {
    top: 5px;
    right: 5px;
  }
}
